import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { FaTimes } from "react-icons/fa";

const apiurl = process.env.REACT_APP_API_URL_BACKEND;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "500px",
    maxHeight: "80%",
    overflow: "auto",
    backgroundColor: "#ffffff",
    border: "none",
    borderRadius: "12px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
    padding: "30px",
  },
};

const LeadFormModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsOpen(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?\d{10,15}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};

    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }
    if (!formData.message) errors.message = "Message is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setFormErrors({});

    try {
      const response = await axios.post(`${apiurl}/contact`, formData);
      console.log("Form submitted successfully!", response.data);
      closeModal();
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Lead Generation Form Modal"
      style={customStyles}
    >
      <div className="lead-form">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="text-primary mb-0">Sign Up for Latest Updates</h2>
          <button
            className="close-button bg-transparent border-0 p-0"
            onClick={closeModal}
          >
            <FaTimes size={24} color="#333" />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label className="mb-1">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className={`form-control ${formErrors.name ? "is-invalid" : ""}`}
            />
            {formErrors.name && (
              <div className="invalid-feedback">{formErrors.name}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="mb-1">Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
            />
            {formErrors.email && (
              <div className="invalid-feedback">{formErrors.email}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="mb-1">Phone Number:</label>
            <input
              type="tel"
              name="phoneNumber"
              maxLength={15}
              value={formData.phoneNumber}
              onChange={handlePhoneNumberChange}
              className={`form-control ${
                formErrors.phoneNumber ? "is-invalid" : ""
              }`}
            />
            {formErrors.phoneNumber && (
              <div className="invalid-feedback">{formErrors.phoneNumber}</div>
            )}
          </div>
          <div className="form-group mb-4">
            <label className="mb-1">Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              className={`form-control ${
                formErrors.message ? "is-invalid" : ""
              }`}
              rows="4"
            />
            {formErrors.message && (
              <div className="invalid-feedback">{formErrors.message}</div>
            )}
          </div>
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default LeadFormModal;
