
import React, { useEffect, useState, lazy, Suspense } from "react";
import Modal from "react-modal";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { auth } from "./firebase.config";
import "./index.css";
import LeadFormModal from "../src/components/LeadFormModal";

Modal.setAppElement("#root");

// Lazy load components
const HomePage = lazy(() => import("./components/Homepage"));
const LoginWithOTP = lazy(() => import("./components/Loginwithotp"));
const Landingpage = lazy(() => import("./components/Landingpage"));
const TermsAndConditions = lazy(() => import("./components/Termsandconditions"));
const ContactUs = lazy(() => import("./components/Contactus"));
const AboutUs = lazy(() => import("./components/Aboutus"));
const RefundPolicyPage = lazy(() => import("./components/RefundPolicyPage"));
const Navbarr = lazy(() => import("./components/Navbar"));
const Footerr = lazy(() => import("./components/Footerr"));
const Discoverthtaxsavingoption = lazy(() => import("./components/Discovertax"));

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-5 ">
        <FaSpinner className="spinner" size={42} />
      </div>
    );
  }

  return (
    <Router>
      <Suspense fallback={<div className=" d-flex justify-content-center mt-5"> <FaSpinner className="spinner" size={42} /></div>}>
        <Navbarr />
        <Routes>
          <Route
            path="/home"
            element={user ? <HomePage /> : <Navigate to="/" />}
          />
          <Route path="/login" element={<LoginWithOTP />} />
          <Route path="/" element={<Landingpage />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/refundpolicy" element={<RefundPolicyPage />} />
          <Route path="/discover" element={<Discoverthtaxsavingoption />} />
          <Route path="/leadform" element={<LeadFormModal />} />
        </Routes>
        <Footerr />
      </Suspense>
    </Router>
  );
};

export default App;
