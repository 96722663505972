import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
  authDomain: process.env.REACT_APP_FIREBASEAUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASEPROJECTID,
  storageBucket:process.env.REACT_APP_FIREBASEstorageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASEmessagingSenderId,
  appId: process.env.REACT_APP_FIREBASEappId,
  measurementId: process.env.REACT_APP_FIREBASEmeasurementId,
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

auth.onIdTokenChanged(async (user) => {
  if (user) {
    const token = await user.getIdToken();
    localStorage.setItem("Token", token);
  } else {
    localStorage.removeItem("Token");
  }
});

export { auth, app };
